import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import ScreenerQuestionEditor from 'Pages/manager/scenarios/ManagerEditScreener/ScreenerQuestionEditor';
import ManagerLayout from 'Pages/manager/ManagerLayout';
import { managerMutations, managerQueries, ScreenerQuestion } from 'Features/manager/shared/queries';
import { useParams } from 'react-router-dom';

const ManagerEditScreener = () => {
    const { bucketId } = useParams<{ bucketId: string }>();
    const [questions, setQuestions] = useState<ScreenerQuestion[]>([]);
    const [currentQuestion, setCurrentQuestion] = useState<ScreenerQuestion | null>(null);
    const [questionIdx, setQuestionIdx] = useState<number | undefined>(undefined);
    const { mutateAsync: saveScreener } = managerMutations.usePutScreener();
    const { data: screener, isLoading } = managerQueries.useScreener(bucketId || '');

    const onSave = (question: ScreenerQuestion) => {
        question.position = questions.length + 1;
        if (questionIdx !== undefined) {
            setQuestions((prevQuestions) => {
                const updatedQuestions = [...prevQuestions];
                updatedQuestions[questionIdx] = question;
                return updatedQuestions;
            });

            const updatedQuestions = [...questions];
            updatedQuestions[questionIdx] = question;
            saveScreener({
                urlParams: { bucketId: bucketId || '' },
                body: { title: '', screenerQuestions: updatedQuestions },
            }).then(() => {
                setCurrentQuestion(null); // reset the current question after saving
                setQuestionIdx(undefined);
            });
        } else {
            setQuestions((prevQuestions) => [...prevQuestions, question]);

            console.log('values', [...questions, question]);
            saveScreener({
                urlParams: { bucketId: bucketId || '' },
                body: { title: '', screenerQuestions: [...questions, question] },
            }).then(() => {
                setCurrentQuestion(null); // reset the current question after saving
                setQuestionIdx(undefined);
            });
        }
    };

    const handleEdit = (question: ScreenerQuestion, idx: number) => {
        setCurrentQuestion(question);
        setQuestionIdx(idx);
    };

    const handleDelete = (idx: number) => {
        setQuestions((prevQuestions) => prevQuestions.filter((q, i) => i !== idx));
        saveScreener({
            urlParams: { bucketId: bucketId || '' },
            body: { title: '', screenerQuestions: questions.filter((q, i) => i !== idx) },
        });
    };

    useEffect(() => {
        if (screener) {
            setQuestions(screener.screenerQuestions);
        }
    }, [screener]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <ManagerLayout>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Question Text</TableCell>
                            <TableCell>Number of Options</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.map((question, idx) => (
                            <TableRow key={question.id}>
                                <TableCell>{question.text}</TableCell>
                                <TableCell>{question.options.length}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEdit(question, idx)}>Edit</Button>
                                    <Button onClick={() => handleDelete(idx)}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ScreenerQuestionEditor questionIdx={questionIdx} currentQuestion={currentQuestion} onSave={onSave} />
        </ManagerLayout>
    );
};

export default ManagerEditScreener;
